//
// Profile card
//

.card-profile-image {
  position: relative;

  img {
    max-width: 180px;
    border-radius: $border-radius;
    // @extend .shadow;
    transform: translate(-50%,-30%);
    position: absolute;
    left: 50%;
    transition: $transition-base;

    &:hover {
      transform: translate(-50%, -33%);
    }

    &.castle-img {
      height: 145px;
      width: auto;
      max-width: 300px;
    }

    &.vimpel-img {
      max-width: 300px;
      transform: translate(-50%, 175%);
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

.card-profile-stats {
  padding: 0rem 0;
  padding-top: 1rem;

  > div {
    text-align: center;
    margin-right: 1rem;
    padding: .875rem;
    padding-bottom: 0px;

    &:last-child {
      margin-right: 0;
    }

    .heading {
      font-size: 1.1rem;
      font-weight: bold;
      display: block;
    }

    .vimpel-img-text {
      text-transform: none;
      color: #fff;
      font-size: 1.1rem;
      font-weight: bold;
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 1px;
      margin-top: -2px;
    }

    .description {
      font-size: .875rem;
      color: $gray-500;
    }
  }

  &.vimpel-img-level {
    margin-bottom: 1.5rem;
  }
}

.card-profile-awards {
  margin-top: 0rem !important;
  padding-top: 0rem;
  padding-bottom: 2rem !important;

  &.xplvl {
    padding-bottom: 0rem !important;
  }
}

.card-profile-no-awards {
  padding-bottom: 2rem !important;
}

.card-profile-actions {
  padding: .875rem;
}
